import React from 'react';
import ReactDOM from 'react-dom/client';
import './Post.css';

function Post(props) {
    return (
        <div className='Post'>
            <div className="postImage"><img src={props.imgSource} alt="Błąd ładowania obrazu."/></div>
            <div className="postPreview">{props.postPreview ? props.postPreview : "Błąd ładowania podglądu."}</div>
        </div>
    )
}

export default Post;
