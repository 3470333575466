import logowhite from './Images/logowhite.png';
function post(tittle, text, postPreview, imgSource){
    this.tittle = tittle;
    this.text = text;
    this.postPreview = postPreview;
    this.imgSource = imgSource;
}

let posts = [];

// Posty

posts.push(new post("Tottle", "ududisdhiashdiuad", "dasfsfsdfds", "https://taniaudyt.com/static/media/logowhite.png"));
posts.push(new post("Tottle", "ududisdhiashdiuad", "dasfsfsdfds", "./Images/logoblack.png"));
posts.push(new post("Tottle", "ududisdhiashdiuad", "dasfsfsdfds", "./Images/logoblack.png"));
posts.push(new post("Tottle", "ududisdhiashdiuad", "dasfsfsdfds", "./Images/logoblack.png"));


// Koniec

export default posts;



