import logowhite from './Images/logowhite.png';
import './App.css';
import Post from './Post.js';
import posts from './postsToDisplay.js';

function App() {
  return (
    <div>
      <div id="headder">
        <div id="logo"><img src={logowhite} alt="Logo"/></div>
        <div id="menu"></div>
      </div>
      <div id="main">
      <Post postPreview={posts[0].postPreview} imgSource={posts[0].imgSource} />
        <Post postPreview={posts[1].postPreview} imgSource={posts[1].imgSource} />
        <Post postPreview={posts[2].postPreview} imgSource={posts[2].imgSource} />
      </div>
      <div id="footer"></div>
    </div>
  );
}

export default App;
